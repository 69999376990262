const projectCardData = [
    {
        img:"https://www.freecodecamp.org/news/content/images/2021/06/portfolio-1-min.gif",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    },
    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlyeJ7xVil9e9P-aMa_MKlcZn4y85IkXL2_xEk7q4ue2eROTtfJt2R3a2Kpc2W2uiURCo&usqp=CAU",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    },
    {
        img:"https://madewithreact.com/assets/img/react-light-bootstrap-dashboard-pro.jpg?v=1f2ec887e3",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    },    {
        img:"https://user-images.githubusercontent.com/29565530/144163917-196b3e87-90e2-4615-b1c7-6a905533f34b.gif",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    },    {
        img:"https://repository-images.githubusercontent.com/533462267/f8774826-7f92-454f-828c-4e0ad468a083",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    },    {
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT49qY-iDE0wK11jpD7Uk5jOek3XLfKG-wOcFkZSSMy2UmeExcm3M3mpbbD_DrlG4f5_DE&usqp=CAU",
        title:"React Admon Design",
        text:"Loreorem Ipsum of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        view:"#",
        source:"#"
    }
]
export default projectCardData